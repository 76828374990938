import React from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../../../../../hooks/useScreenSize';
import DefaultPicture, {
  propTypes as defaultPicturePropTypes,
} from '../../../../01_atoms/DefaultPicture';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';
import Link, { propTypes as linkPropTypes } from '../../../../01_atoms/Link';
import FormattedText from '../../../../01_atoms/FormattedText';
import LogoWhite from '../../../../../public/static/icons/logos/logo-white.svg';

import styles from './index.module.scss';

const StatisticList = ({ variant, items }) => (
  <ul
    className={`${styles['statistic-list']} ${
      variant === 'emergency' ? styles['statistic-list--red'] : ''
    }`}
  >
    {items.map(
      (item) =>
        item.title &&
        item.text && (
          <li className={styles['statistic-list__list-item']} key={item.title}>
            {item.title && (
              <h3 className={styles['statistic-list__list-item-title']}>{item.title}</h3>
            )}
            {item.text && (
              <FormattedText
                className={styles['statistic-list__list-item-text']}
                text={item.text}
              />
            )}
          </li>
        ),
    )}
  </ul>
);

const statisticListPropTypes = {
  variant: PropTypes.oneOf(['default', 'emergency']).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
};

StatisticList.propTypes = statisticListPropTypes;

const BBHeroSocialAdBase = ({
  logoLink,
  title,
  label = '',
  items,
  image = null,
  variant,
  behaviorSettings = null,
  showDEC = false,
  uuid = null,
}) => {
  const { width } = useScreenSize();
  const isDesktop = width >= 1200;

  const classes = [
    'bb',
    'bb-hero',
    styles['bb-hero-social-ad-base'],
    styles[`bb-hero-social-ad-base--variant-${variant}`],
    styles[`bb-hero-social-ad-base--layout-${image ? 'with-image' : 'no-image'}`],
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];

  return (
    <div id={uuid} className={classes.join(' ')}>
      <div className={styles['bb-hero-social-ad-base__top-section']}>
        <div className="container">
          {logoLink && (
            <div className={styles['bb-hero-social-ad-base__site-logo']}>
              <Link {...logoLink}>
                <a tabIndex="0">
                  <LogoWhite
                    alt="Concern Worldwide"
                    className={styles['bb-hero-social-ad-base__logo-image']}
                    aria-label="Concern Worldwide"
                  />
                </a>
              </Link>
            </div>
          )}
          <div className={styles['bb-hero-social-ad-base__content']}>
            <h1 className={styles['bb-hero-social-ad-base__title']}>{title}</h1>
            {!image && isDesktop && (
              <div className={styles['bb-hero-social-ad-base__top-info']}>
                {label && (
                  <div
                    className={`${styles['bb-hero-social-ad-base__label']} ${showDEC ? styles['bb-hero-social-ad-base__label--dec'] : ''}`}
                  >
                    {label}
                  </div>
                )}
                {!label && showDEC && (
                  <div className={styles['bb-hero-social-ad-base__label--empty']}></div>
                )}
                <StatisticList items={items} variant={variant} />
              </div>
            )}
          </div>
        </div>
        {showDEC && (
          <>
            <div className="red-strip" />
            <div className="d-lg-none">
              <img
                src="/static/icons/DEC-small.svg"
                alt="DEC logo"
                className="watermark"
                width={119}
                height={34}
              />
            </div>
            <div className="d-none d-lg-block">
              <img
                src="/static/icons/DEC-horizontal.svg"
                alt="DEC logo"
                className="watermark"
                width={164}
                height={90}
              />
            </div>
          </>
        )}
      </div>
      {(image || !isDesktop) && (
        <div className={styles['bb-hero-social-ad-base__bottom-section']}>
          <div className="container">
            <div className={styles['bb-hero-social-ad-base__content']}>
              {image && (
                <div className={styles['bb-hero-social-ad-base__bottom-image']}>
                  <div className={styles['bb-hero-social-ad-base__image']}>
                    <DefaultPicture {...image} />
                  </div>
                </div>
              )}
              <div className={styles['bb-hero-social-ad-base__bottom-info']}>
                {label && <div className={styles['bb-hero-social-ad-base__label']}>{label}</div>}
                <StatisticList items={items} variant={variant} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

BBHeroSocialAdBase.propTypes = {
  ...statisticListPropTypes,
  logoLink: PropTypes.shape(linkPropTypes).isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  image: PropTypes.shape(defaultPicturePropTypes),
  behaviorSettings: behaviorSettingsProps,
  showDEC: PropTypes.bool,
  uuid: PropTypes.string,
};

export default BBHeroSocialAdBase;
