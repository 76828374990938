import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

// Core blocks (used above the fold).
import BBBreadcrumbs from '../../03_organisms/BodyBlocks/BBBreadcrumbs';
import BBDetailMeta from '../../03_organisms/BodyBlocks/BBDetailMeta';
import BBHeading from '../../03_organisms/BodyBlocks/Text/BBHeading';
import BBHeadingWithAnimation from '../../03_organisms/BodyBlocks/Text/BBHeadingWithAnimation';
import BBText from '../../03_organisms/BodyBlocks/Text/BBText';
import BBContentOverview from '../../03_organisms/BodyBlocks/BBContentOverview';
import BBVideo from '../../03_organisms/BodyBlocks/BBVideo';
import BBHeroVideo from '../../03_organisms/BodyBlocks/BBHero/BBHeroVideo';
import BBHeroImage from '../../03_organisms/BodyBlocks/BBHero/BBHeroImage';
import BBHeroEmergency from '../../03_organisms/BodyBlocks/BBHero/BBHeroEmergency';
import BBHeroLanding from '../../03_organisms/BodyBlocks/BBHero/BBHeroLanding';
import BBHeroHubHomePage from '../../03_organisms/BodyBlocks/BBHero/BBHeroHubHomePage';
import BBHeroTagline from '../../03_organisms/BodyBlocks/BBHero/BBHeroTagline';
import BBHeroHomepageBrandedTagline from '../../03_organisms/BodyBlocks/BBHero/BBHeroHomepageBrandedTagline';
import BBHeroWhiteFontStrapline from '../../03_organisms/BodyBlocks/BBHero/BBHeroWhiteFontStrapline';
import BBHeroEmergencyOverlay from '../../03_organisms/BodyBlocks/BBHero/BBHeroEmergencyOverlay';
import BBInlineCTA from '../../03_organisms/BodyBlocks/CallToActions/BBInlineCTA';
import BBSectionWithCharts from '../../03_organisms/BodyBlocks/BBSectionWithCharts';
import BBOurFocus from '../../03_organisms/BodyBlocks/BBOurFocus';
import BBImpactStats from '../../03_organisms/BodyBlocks/BBImpactStats';
import BBImpactTimeline from '../../03_organisms/BodyBlocks/BBImpactTimeline';
import CenteredColumn from '../../04_templates/ContentLayouts/CenteredColumn';
import TwoColumnsLayout from '../../04_templates/ContentLayouts/TwoColumns';
import LeftColumn from '../../04_templates/ContentLayouts/LeftColumn';
import ActivismPage from '../../04_templates/PageLayouts/ActivismPage';
import ReactiveSearchAware from '../../04_templates/PageLayouts/ReactiveSearchAware';
import BBThreeTile from '../../03_organisms/BodyBlocks/BBThreeTile';
import BBTwoColumnAppeals from '../../03_organisms/BodyBlocks/BBTwoColumnAppeals';
import BBTwoThreeTiles from '../../03_organisms/BodyBlocks/BBTwoThreeTiles';
import BBHeroHeading from '../../03_organisms/BodyBlocks/Text/BBHeroHeading';
import BBImageGrid from '../../03_organisms/BodyBlocks/BBImageGrid';
import BBHeroWithMoneyHandles from '../../03_organisms/BodyBlocks/BBHeroWithMoneyHandles';
import BBSidebarTableOfContents from '../../03_organisms/BodyBlocks/BBSidebarTableOfContents';
import BBActivismHero from '../../03_organisms/BodyBlocks/BBHero/BBActivismHero';
import BBHeroWithDonationWidget from '../../03_organisms/BodyBlocks/BBHero/BBHeroWithDonationWidget';
import BBHeroSocialAd from '../../03_organisms/BodyBlocks/BBHero/BBHeroSocialAd';
import BBHeroSocialAdBase from '../../03_organisms/BodyBlocks/BBHero/BBHeroSocialAdBase';
import BBHeroImageLogo from '../../03_organisms/BodyBlocks/BBHero/BBHeroImageLogo';
// Doesn't work with dynamic loading & SSR, not sure why.
// Re-try when withBreakpoints() is refactored.
import BBRelatedContent from '../../03_organisms/BodyBlocks/BBRelatedContent';
import BBHubSearch from '../../03_organisms/BodyBlocks/BBHubSearch';

// TODO: Check if we need to load the styles here.
import heroStyles from '../../03_organisms/BodyBlocks/BBHeroWithMoneyHandles/index.module.scss';

// Hero Raisely donation form.
const BBHeroRaiselyForm = dynamic(() => import('../../03_organisms/BodyBlocks/BBHeroRaiselyForm'));

// Donation widget blocks.
const BBMoneyHandlesNew = dynamic(() => import('../../03_organisms/BodyBlocks/BBMoneyHandlesNew'));
const BBDonationWidget = dynamic(() => import('../../03_organisms/BodyBlocks/BBDonationWidget'));
const BBQuickDonateWidget = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBQuickDonateWidget'),
);
const BBOfflineDonation = dynamic(() => import('../../03_organisms/BodyBlocks/BBOfflineDonation'));

// All other blocks.
const BBHubResources = dynamic(() => import('../../03_organisms/BodyBlocks/BBHubResources'));
const BBRecommendations = dynamic(() => import('../../03_organisms/BodyBlocks/BBRecommendations'));
const BBHeroRightSideImage = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBHeroRightSideImage'),
);
const BBYoutubeVideos = dynamic(() => import('../../03_organisms/BodyBlocks/BBYoutubeVideos'));
const BBFullWidthBanner = dynamic(() => import('../../03_organisms/BodyBlocks/BBFullWidthBanner'));
const BBCurrentNewsBanner = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBCurrentNewsBanner'),
);
const BBMapWithCountries = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBMapWithCountries'),
);
const BBTextGrid = dynamic(() => import('../../03_organisms/BodyBlocks/BBTextGrid'));
const BBCollageWithBulletPoints = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBCollageWithBulletPoints'),
);
const BBFourTileImpact = dynamic(() => import('../../03_organisms/BodyBlocks/BBFourTileImpact'));
const BBWhereYourMoneyGoesSVG = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBWhereYourMoneyGoesSVG'),
);
const BBWhereMyMoneyGoes = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBWhereMyMoneyGoes'),
);

const BBOtherWaysToDonate = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBOtherWaysToDonate'),
);
const BBTimeSpent = dynamic(() => import('../../03_organisms/BodyBlocks/BBTimeSpent'));
const BBCollapsedText = dynamic(() => import('../../03_organisms/BodyBlocks/Text/BBCollapsedText'));
const BBSocialMediaEmbed = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBSocialMediaEmbed'),
);
const BBSocialMediaFeedEmbed = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBSocialMediaFeedEmbed'),
);
const BBTeaserWithPager = dynamic(() => import('../../03_organisms/BodyBlocks/BBTeaserWithPager'));
const BBResourcesDownload = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBResourcesDownload'),
);
const BBResourcesList = dynamic(() => import('../../03_organisms/BodyBlocks/BBResourcesList'));
const BBStepper = dynamic(() => import('../../03_organisms/BodyBlocks/BBStepper'));
const BBQuoteImage = dynamic(() => import('../../03_organisms/BodyBlocks/BBQuote/BBQuoteImage'));
const BBQuoteSimple = dynamic(() => import('../../03_organisms/BodyBlocks/BBQuote/BBQuoteSimple'));
const BBNewsletterDotdigitalSignUp = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBForms/BBNewsletterDotdigitalSignUp'),
);
const BBFunders = dynamic(() => import('../../03_organisms/BodyBlocks/BBFunders'));
const BBPublications = dynamic(() => import('../../03_organisms/BodyBlocks/BBPublications'));
const BBPartners = dynamic(() => import('../../03_organisms/BodyBlocks/BBPartners'));
const BBFeaturedNews = dynamic(() => import('../../03_organisms/BodyBlocks/BBFeaturedNews'));
const BBFeaturedCountryNews = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBFeaturedCountryNews'),
);
const BBFeaturedStories = dynamic(() => import('../../03_organisms/BodyBlocks/BBFeaturedStories'));
const BBFeaturedEvent = dynamic(() => import('../../03_organisms/BodyBlocks/BBFeaturedEvent'));
const BBPublicationAuthor = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBPublicationAuthor'),
);
const BBFocusArea = dynamic(() => import('../../03_organisms/BodyBlocks/BBFocusArea'));
const BBAccordion = dynamic(() => import('../../03_organisms/BodyBlocks/BBAccordion'));
const BBCarousel = dynamic(() => import('../../03_organisms/BodyBlocks/BBCarousel'));
const BBSidebarHighlights = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBSidebarHighlights'),
);
const BBProgramme = dynamic(() => import('../../03_organisms/BodyBlocks/BBProgramme'));
const BBGiftsBanner = dynamic(() => import('../../03_organisms/BodyBlocks/BBGiftsBanner'));
const BBCTADual = dynamic(() => import('../../03_organisms/BodyBlocks/CallToActions/BBCTADual'));
const BBCTAPrimary = dynamic(
  () => import('../../03_organisms/BodyBlocks/CallToActions/BBCTAPrimary'),
);
const BBCTAEmergencyPrimary = dynamic(
  () => import('../../03_organisms/BodyBlocks/CallToActions/BBCTAEmergencyPrimary'),
);
const BBCTASecondary = dynamic(
  () => import('../../03_organisms/BodyBlocks/CallToActions/BBCTASecondary'),
);
const BBSimpleTextForm = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBForms/BBSimpleTextForm'),
);
const BBWhereWeWork = dynamic(() => import('../../03_organisms/BodyBlocks/BBWhereWeWork'));
const BBSocialMediaSideShare = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBSocialMediaSideShare'),
);
const BBRawHtml = dynamic(() => import('../../03_organisms/BodyBlocks/BBRawHtml'));
const BBSoundCloud = dynamic(() => import('../../03_organisms/BodyBlocks/BBSoundCloud'));
const BBFeaturedEventImage = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBFeaturedEventImage'),
);
const BBEventsGrid = dynamic(() => import('../../03_organisms/BodyBlocks/BBEventsGrid'));
const BBMultipleSteps = dynamic(() => import('../../03_organisms/BodyBlocks/BBMultipleSteps'));
const BBGiftaid = dynamic(() => import('../../03_organisms/BodyBlocks/BBGiftaid'));
const BBWebform = dynamic(() => import('../../03_organisms/BodyBlocks/BBWebform'));
const BBQuickLinks = dynamic(() => import('../../03_organisms/BodyBlocks/BBQuickLinks'));
const BBImageWithCaption = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBImageWithCaption'),
);
const BBTextWithSideImage = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBTextWithSideImage'),
);
const BBHighlightedListWithIcons = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBHighlightedListWithIcons'),
);
const BBCardCarousel = dynamic(() => import('../../03_organisms/BodyBlocks/BBCardCarousel'));
const BBMoneyHandlesInlineCheckout = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBMoneyHandlesInlineCheckout'),
);
const BBMovingImageCollage = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBMovingImageCollage'),
);

// Activism blocks.
const BBActivismTextForm = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBForms/BBActivismTextForm'),
);
const BBActivismStoryCarousel = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBActivismStoryCarousel'),
);
const BBActivismResources = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBActivismResources'),
);
const BBCTAActivism = dynamic(
  () => import('../../03_organisms/BodyBlocks/CallToActions/BBCTAActivism'),
);
const BBActivismGoals = dynamic(() => import('../../03_organisms/BodyBlocks/BBActivismGoals'));
const BBActivismQuote = dynamic(() => import('../../03_organisms/BodyBlocks/BBActivismQuote'));
const BBActivismWebform = dynamic(() => import('../../03_organisms/BodyBlocks/BBActivismWebform'));
const BBActivismFourTile = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBActivismFourTile'),
);
const BBActivismCTAWithBackground = dynamic(
  () => import('../../03_organisms/BodyBlocks/CallToActions/BBActivismCTAWithBackground'),
);
const BBActivismInfoWithIcons = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBActivismInfoWithIcons'),
);

// Legacy blocks (should not be used any more, but we keep them here for older site pages).
const BBAppealOverlayLegacy = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBAppealOverlay'),
);
// const BBMoneyHandlesLegacy = dynamic(() => import('../../03_organisms/BodyBlocks/BBMoneyHandles'));
// const BBDonationsHeaderLegacy = dynamic(
//   () => import('../../03_organisms/BodyBlocks/BBDonationsHeader'),
// );

const BBInlineDonationForm = dynamic(
  () => import('../../03_organisms/BodyBlocks/BBInlineDonationForm'),
);

const GiftsBBText = dynamic(() => import('../../../componentsGifts/BodyBlocks/BBText'));
const GiftsBBTextWithTitle = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBTextWithTitle'),
);
const GiftsBBHeroImage = dynamic(() => import('../../../componentsGifts/BodyBlocks/BBHeroImage'));
const GiftsBBGiftsGrid = dynamic(() => import('../../../componentsGifts/BodyBlocks/BBGiftsGrid'));
const GiftsBBFAQ = dynamic(() => import('../../../componentsGifts/BodyBlocks/BBFAQ'));
const GiftsBBFAQWithImage = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBFAQWithImage'),
);
const GiftsBBButton = dynamic(() => import('../../../componentsGifts/BodyBlocks/BBButton'));
const GiftsBBContactUs = dynamic(() => import('../../../componentsGifts/BodyBlocks/BBContactUs'));
const GiftsBBHeroStraplineWithDecoration = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBHeroStraplineWithDecoration'),
);
const GiftsBBPopularGiftsRow = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBPopularGiftsRow'),
);
const GiftsBBStepperWithIcons = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBStepperWithIcons'),
);
const GiftsBBTextWithTableContent = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBTextWithTableContent'),
);
const GiftsBBCatalogWithFilters = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBCatalogWithFilters'),
);
const GiftsBBHeroWithTwoButtons = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBHeroWithTwoButtons'),
);
const GiftsCorporateGiftsGrid = dynamic(
  () => import('../../../componentsGifts/CorporateGiftsGrid'),
);
const GiftsBBColorfulSliderWithIcons = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBColorfulSliderWithIcons'),
);
const GiftsBBStatsSectionWithAnimation = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBStatsSectionWithAnimation'),
);
const GiftsBBHeroSantaLetter = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBHeroSantaLetter'),
);
const GiftsBBCarousel = dynamic(() => import('../../../componentsGifts/BodyBlocks/BBCarousel'));
const GiftsBBInlineCta = dynamic(() => import('../../../componentsGifts/BodyBlocks/BBInlineCta'));
const GiftsBBListWithIcons = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBListWithIcons'),
);
const GiftsBBFeaturedCategories = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBFeaturedCategories'),
);

// TODO: Import the component dynamically. Currently we load it without dynamic import
//  since Popups styles are not loading during the client routing. It needs to be investigated.
// const GiftsBBSantaLetter = dynamic(
//   () => import('../../../componentsGifts/BodyBlocks/BBSantaLetter'),
// );
import GiftsBBSantaLetter from '../../../componentsGifts/BodyBlocks/BBSantaLetter';

const GiftsBBSantaLetterGallery = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBSantaLetterGallery'),
);
const BBGiftsImpactBlock = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBGiftsImpactBlock'),
);
const BBGiftsProductsCarousel = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBProductsCarousel'),
);
const BBGiftsInlineBasketCta = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBInlineBasketCta'),
);
const BBGiftsProductSection = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBProductSection'),
);
const BBGiftsRelatedProducts = dynamic(
  () => import('../../../componentsGifts/BodyBlocks/BBRelatedProducts'),
);
const BBDotdigitalContactForm = dynamic(
  () => import('../../../components/03_organisms/BodyBlocks/BBForms/BBDotdigitalContactForm'),
);
const BBShareInSocialMedia = dynamic(
  () => import('../../../components/03_organisms/BodyBlocks/BBShareInSocialMedia'),
);

const BBThankYouPersonalisedMessage = dynamic(
  () => import('../../../components/03_organisms/BodyBlocks/BBThankYouPersonalisedMessage'),
);
const BBThankYouDonationDetails = dynamic(
  () => import('../../../components/03_organisms/BodyBlocks/BBThankYouDonationDetails'),
);

const BBUpselling = dynamic(() => import('../../03_organisms/BodyBlocks/BBUpselling'));

const BBCrossSelling = dynamic(() => import('../../03_organisms/BodyBlocks/BBCrossSelling'));

const components = {
  // Gifts body blocks.
  gifts_grid: GiftsBBGiftsGrid,
  gifts_faq: GiftsBBFAQ,
  gifts_faq_with_image: GiftsBBFAQWithImage,
  gifts_popular_row: GiftsBBPopularGiftsRow,
  gifts_text_html: GiftsBBText,
  gifts_text_with_title: GiftsBBTextWithTitle,
  gifts_primary_button: GiftsBBButton,
  gifts_hero_image: GiftsBBHeroImage,
  gifts_contact_us: GiftsBBContactUs,
  gifts_hero_strapline_decoration: GiftsBBHeroStraplineWithDecoration,
  gifts_stepper_with_icons: GiftsBBStepperWithIcons,
  gifts_text_with_table_content: GiftsBBTextWithTableContent,
  gifts_catalog: GiftsBBCatalogWithFilters,
  gifts_corporate_catalog: GiftsCorporateGiftsGrid,
  gifts_hero_with_two_buttons: GiftsBBHeroWithTwoButtons,
  gifts_colorful_slider_with_icons: GiftsBBColorfulSliderWithIcons,
  stats_section_with_animation: GiftsBBStatsSectionWithAnimation,
  gifts_hero_santa_letter: GiftsBBHeroSantaLetter,
  gifts_carousel: GiftsBBCarousel,
  gifts_inline_cta: GiftsBBInlineCta,
  gifts_featured_categories: GiftsBBFeaturedCategories,
  gifts_list_with_icons: GiftsBBListWithIcons,
  gifts_santa_letter: GiftsBBSantaLetter,
  gifts_impact_block: BBGiftsImpactBlock,
  gifts_santa_letter_gallery: GiftsBBSantaLetterGallery,
  gifts_products_carousel: BBGiftsProductsCarousel,
  gifts_inline_basket_cta: BBGiftsInlineBasketCta,
  gifts_product_section: BBGiftsProductSection,
  gifts_related_products: BBGiftsRelatedProducts,
  // CNET body blocks.
  breadcrumbs: BBBreadcrumbs,
  news_meta_info: BBDetailMeta,
  hero_heading: BBHeroHeading,
  text_heading: BBHeading,
  text_heading_2: BBHeading,
  text_heading_3: BBHeading,
  text_heading_4: BBHeading,
  text_heading_2_rockitt: BBHeading,
  headline_with_animated_highlight: BBHeadingWithAnimation,
  text_text: BBText,
  text_disclaimer: BBText,
  text_text_large: BBText,
  text_text_collapsed: BBCollapsedText,
  text_with_side_image: BBTextWithSideImage,
  embed_instagram: BBSocialMediaEmbed,
  embed_facebook: BBSocialMediaEmbed,
  embed_twitter: BBSocialMediaEmbed,
  embed_soundcloud: BBSoundCloud,
  embed_tiktok_sociablekit: BBSocialMediaFeedEmbed,
  inline_image: BBImageGrid,
  inline_image_with_caption: BBImageWithCaption,
  inline_donation_form: BBInlineDonationForm,
  media_documents_teasers: BBTeaserWithPager,
  media_image_grid: BBImageGrid,
  media_documents_with_links: BBResourcesDownload,
  media_documents_with_previews: BBResourcesList,
  related_publications: BBRelatedContent,
  in_this_section: BBRelatedContent,
  hero_standard_info: BBHeroImage,
  stepper: BBStepper,
  image_quote: BBQuoteImage,
  simple_quote: BBQuoteSimple,
  newsletter_dotmailer: BBNewsletterDotdigitalSignUp,
  dotdigital_contact_form: BBDotdigitalContactForm,
  listing_organisations_with_logo: BBFunders,
  listing_teasers_with_intro: BBPublications,
  listing_organisations_with_link: BBPartners,
  listing_latest_content: BBFeaturedNews,
  listing_content_grid: BBFeaturedCountryNews,
  listing_teasers: BBRelatedContent,
  listing_teasers_custom: BBRelatedContent,
  listing_content_overview: BBContentOverview,
  listing_top_content: BBFeaturedStories,
  cta_primary: BBCTAPrimary,
  cta_primary_appeal: BBCTAPrimary,
  sidebar_country: BBTimeSpent,
  author: BBPublicationAuthor,
  key_focus_area: BBFocusArea,
  external_video: BBVideo,
  youtube_videos: BBYoutubeVideos,
  hero_video: BBHeroVideo,
  hero_video_emergency: BBHeroVideo,
  hero_story: BBHeroImage,
  hero_landing: BBHeroLanding,
  hero_hub_home_page: BBHeroHubHomePage,
  hero_dec: BBHeroEmergency,
  hero_emergency: BBHeroEmergency,
  hero_tagline: BBHeroTagline,
  hero_white_font_strapline: BBHeroWhiteFontStrapline,
  hero_homepage_branded_tagline: BBHeroHomepageBrandedTagline,
  hero_emergency_overlay: BBHeroEmergencyOverlay,
  highlighted_list_with_icons: BBHighlightedListWithIcons,
  slider_standard_carousel: BBCarousel,
  accordion: BBAccordion,
  inline_cta: BBInlineCTA,
  cta_button_primary: BBInlineCTA,
  cta_button_secondary: BBInlineCTA,
  slider_story_carousel: BBCarousel,
  sidebar_highlights: BBSidebarHighlights,
  sidebar_table_of_contents: BBSidebarTableOfContents,
  section_with_infographics: BBImpactStats,
  section_with_charts: BBSectionWithCharts,
  section_with_cards: BBOurFocus,
  section_with_sidebar: TwoColumnsLayout,
  section_icon_links: BBCTASecondary,
  section_with_links: BBCTASecondary,
  section_programme: BBProgramme,
  section_other_ways_to_donate: BBOtherWaysToDonate,
  standalone_money_handles: BBMoneyHandlesNew,
  standalone_money_handles_image: BBMoneyHandlesNew,
  donation_widget: BBDonationWidget,
  quick_donate_widget: BBQuickDonateWidget,
  hero_money_handles_form: BBHeroWithMoneyHandles,
  dual_cta: BBCTADual,
  timeline: BBImpactTimeline,
  simple_search_form: BBSimpleTextForm,
  where_we_work: BBWhereWeWork,
  webform: BBWebform,
  social_media_share: BBSocialMediaSideShare,
  html_code: BBRawHtml,
  three_tile: BBThreeTile,
  two_column_appeals: BBTwoColumnAppeals,
  two_three_tiles: BBTwoThreeTiles,
  giftaid: BBGiftaid,
  primary_emergency_cta: BBCTAEmergencyPrimary,
  featured_event: BBFeaturedEvent,
  featured_event_image: BBFeaturedEventImage,
  events_grid: BBEventsGrid,
  multiple_steps: BBMultipleSteps,
  offline_donation: BBOfflineDonation,
  hero_raisely_form: BBHeroRaiselyForm,
  quick_links: BBQuickLinks,
  knowledge_hub_search: BBHubSearch,
  knowledge_hub_resources: BBHubResources,
  related_knowledge_hub_resources: BBHubResources,
  section_with_recommendations: BBRecommendations,
  hero_right_side_image: BBHeroRightSideImage,
  full_width_banner: BBFullWidthBanner,
  current_news: BBCurrentNewsBanner,
  map_with_countries: BBMapWithCountries,
  text_grid: BBTextGrid,
  numbered_text_grid: BBTextGrid,
  four_tile_impact: BBFourTileImpact,
  where_your_money_goes: BBWhereYourMoneyGoesSVG,
  where_my_money_goes: BBWhereMyMoneyGoes,
  cnet_gifts_banner: BBGiftsBanner,
  hero_with_donation_widget: BBHeroWithDonationWidget,
  hero_with_donation_widget_emerge: BBHeroWithDonationWidget,
  cross_selling: BBCrossSelling,
  upselling: BBUpselling,
  // Page types blocks
  activism_hero: BBActivismHero,
  activism_hero_thin: BBActivismHero,
  activism_three_tile: BBThreeTile,
  activism_two_column_appeals: BBTwoColumnAppeals,
  collage_with_bullet_points: BBCollageWithBulletPoints,
  activism_multiple_steps: BBMultipleSteps,
  activism_newsletter_signup: BBActivismTextForm,
  activism_story_carousel: BBActivismStoryCarousel,
  activism_resources: BBActivismResources,
  activism_cta: BBCTAActivism,
  activism_goals: BBActivismGoals,
  activism_quote: BBActivismQuote,
  activism_webform: BBActivismWebform,
  activism_four_tile: BBActivismFourTile,
  activism_cta_with_background: BBActivismCTAWithBackground,
  activism_info_with_icons: BBActivismInfoWithIcons,
  hero_social_ad: BBHeroSocialAd,
  hero_social_ad_emergency: BBHeroSocialAdBase,
  hero_social_ad_brief: BBHeroSocialAdBase,
  hero_image_logo: BBHeroImageLogo,
  card_carousel: BBCardCarousel,
  money_handles_inline_checkout: BBMoneyHandlesInlineCheckout,
  moving_image_collage: BBMovingImageCollage,
  share_in_social_media: BBShareInSocialMedia,
  // Legacy blocks (to be removed in future).
  widget_appeal_slider: BBAppealOverlayLegacy,
  // appeals_hero: BBDonationsHeaderLegacy,
  // donation_hero: BBDonationsHeaderLegacy,
  // money_handles_block: BBMoneyHandlesLegacy,
  // Thank you page blocks.
  thank_you_personalised_message: BBThankYouPersonalisedMessage,
  thank_you_donation_details: BBThankYouDonationDetails,
};

const layoutComponents = {
  centered: CenteredColumn,
  twoColumns: TwoColumnsLayout,
  left: LeftColumn,
};

// List of blocks which should AUTOMATICALLY be wrapped into the centered or
// two columns layout.
const blocksInsideLayout = {
  centered: [
    'breadcrumbs',
    'text_heading',
    'text_heading_2',
    'text_heading_3',
    'text_heading_4',
    'text_text',
    'text_disclaimer',
    'text_text_large',
    'text_text_collapsed',
    'image_quote',
    'simple_quote',
    'media_documents_with_links',
    'author',
    'key_focus_area',
    'external_video',
    'money_handles_block',
    'donation_widget',
    'inline_cta',
    'cta_button_primary',
    'webform',
    'cta_button_secondary',
    'embed_instagram',
    'embed_facebook',
    'embed_twitter',
    'knowledge_hub_resources',
    'map_with_countries',
    'inline_image',
    'inline_image_with_caption',
    'text_grid',
    'numbered_text_grid',
    'headline_with_animated_highlight',
  ],
  twoColumns: [
    'text_heading',
    'text_heading_2',
    'text_heading_3',
    'text_heading_4',
    'text_text',
    'text_disclaimer',
    'text_text_large',
    'text_text_collapsed',
    'inline_image',
    'inline_image_with_caption',
    'image_quote',
    'simple_quote',
    'media_documents_with_links',
    'author',
    'key_focus_area',
    'external_video',
    'webform',
    'money_handles_block',
    'donation_widget',
    'quick_donate_widget',
    'inline_cta',
    'cta_button_primary',
    'cta_button_secondary',
    'embed_instagram',
    'embed_facebook',
    'embed_twitter',
    'knowledge_hub_resources',
    'map_with_countries',
    'text_grid',
    'numbered_text_grid',
    'offline_donation',
    'headline_with_animated_highlight',
  ],
  left: [
    // Each block can have its own customizations for the left layout.
    // See <LeftColumn /> component.
    'breadcrumbs',
    'text_heading',
    'text_heading_2',
    'text_heading_3',
    'text_heading_4',
    'text_text',
    'text_disclaimer',
    'text_text_large',
    'text_text_collapsed',
    'image_quote',
    'simple_quote',
    'media_documents_with_links',
    'author',
    'key_focus_area',
    'external_video',
    'money_handles_block',
    'donation_widget',
    'inline_cta',
    'cta_button_primary',
    'webform',
    'cta_button_secondary',
    'embed_instagram',
    'embed_facebook',
    'embed_twitter',
    'knowledge_hub_resources',
    'inline_image',
    'inline_image_with_caption',
    'media_image_grid',
    'map_with_countries',
    'text_grid',
    'numbered_text_grid',
    'text_with_side_image',
    'offline_donation',
    'headline_with_animated_highlight',
  ],
};

const Paragraphs = ({ blocks = [], layout = 'twoColumns', pageType }) => {
  let output = [];
  const addLayout = (componentList) => {
    // Build a unique layout key based on first component in the list.
    const key = `layout.${componentList[0].key}.${componentList.length}`;
    if (layout === 'centered' || layout === 'left') {
      output.push(<Layout key={key}>{componentList}</Layout>);
    } else if (layout === 'twoColumns') {
      output.push(<Layout key={key} main={componentList} />);
    }
  };
  // Get component for the layout to use.
  const Layout = layoutComponents[layout];
  const thisBlocksInsideLayout = blocksInsideLayout[layout];

  let layoutBlocks = [];

  // Special CSS class for pages with some hero blocks.
  let resetFixedWithClass = '';

  // Flag to wrap resulting output into ReactiveSearch aware container for
  // layout modifications based on search state.
  let reactiveSearchAwareWrapper = null;

  blocks.forEach((block, index) => {
    // Make sure the block is not null.
    if (!block) {
      return;
    }

    // Make sure the block has required param.
    if (!block.blockType) {
      return;
    }

    // Make sure the block has matching component.
    if (typeof components[block.blockType] === 'undefined') {
      return;
    }
    // Body block matching entity bundle of paragraph.
    const Paragraph = components[block.blockType];
    let Component;

    if (
      index === 0 &&
      (block.blockType === 'hero_money_handles_form' || block.blockType === 'hero_raisely_form')
    ) {
      resetFixedWithClass = heroStyles['reset-after-appeal-hero'];
    }

    if (block.blockType === 'knowledge_hub_search') {
      reactiveSearchAwareWrapper = true;
    }

    if (block.blockType === 'section_with_sidebar') {
      const mainBlockComponents = block.mainBlocks.map((mainBlock) => {
        // Make sure the block has matching component.
        if (typeof components[mainBlock.blockType] === 'undefined') {
          return null;
        }
        const BlockParagraph = components[mainBlock.blockType];
        return <BlockParagraph {...mainBlock} key={mainBlock.uuid} />;
      });

      const sidebarBlockComponents = block.sidebarBlocks.map((sidebarBlock) => {
        if (sidebarBlock) {
          // Make sure the block has matching component.
          if (typeof components[sidebarBlock.blockType] === 'undefined') {
            return null;
          }
          const BlockParagraph = components[sidebarBlock.blockType];
          return <BlockParagraph {...sidebarBlock} key={sidebarBlock.uuid} />;
        }
        return null;
      });

      Component = (
        <Paragraph
          key={index} // eslint-disable-line react/no-array-index-key
          main={mainBlockComponents}
          sidebar={sidebarBlockComponents}
          {...block}
        />
      );
    } else {
      // eslint-disable-next-line react/no-array-index-key
      Component = <Paragraph key={index} {...block} />;
    }

    // If the current block should be inside of layout, then instead of
    // rendering it directly just put it inside of array. Then we'll render
    // it as soon as we meet the last block inside of this layout.
    if (thisBlocksInsideLayout.includes(block.blockType)) {
      layoutBlocks.push(Component);

      // If the next block exists and it is not going to be rendered inside
      // of the same layout, then just render this layout.
      if (index + 1 < blocks.length) {
        const nextBlock = blocks[index + 1];
        // Make sure the block is not null.
        if (nextBlock && typeof nextBlock === 'object' && nextBlock.blockType) {
          const nextBlockType = nextBlock.blockType;

          if (!thisBlocksInsideLayout.includes(nextBlockType)) {
            addLayout(layoutBlocks);
            layoutBlocks = [];
          }
        } else {
          // If for some reason the next block is not valid, then we need to
          // add the existing layout blocks into the output.
          addLayout(layoutBlocks);
          layoutBlocks = [];
        }
      } else {
        // If there are no more blocks, then just render the layout.
        addLayout(layoutBlocks);
        layoutBlocks = [];
      }
    } else {
      output.push(Component);
    }
  });

  // We have Hero block that uses fixed page background. The easiest way to
  // reset it is to wrap the rest of the page into an extra div container.
  if (output.length > 1 && resetFixedWithClass) {
    output = [
      output[0], // keep the first block that requested resetting.
      <div key={resetFixedWithClass} className={resetFixedWithClass}>
        {output.slice(1)}
      </div>,
    ];
  }

  // Wrap all body blocks apart from the first one
  // into container with background for all activism pages.
  if (pageType === 'activism') {
    output = [
      output[0], // keep hero block without an additional wrapper.
      <ActivismPage key={pageType}>{output.slice(1)}</ActivismPage>,
    ];
  }

  if (output.length > 1 && reactiveSearchAwareWrapper) {
    output = <ReactiveSearchAware>{output}</ReactiveSearchAware>;
  }

  return output;
};

/**
 * Allow body blocks to run their own getInitialProps functions.
 */
Paragraphs.getInitialProps = async ({ blocks, ...args }) => {
  for (let index in blocks) {
    if (
      blocks[index].blockType &&
      components[blocks[index].blockType] &&
      components[blocks[index].blockType].getInitialProps
    ) {
      blocks[index].initialProps = await components[blocks[index].blockType].getInitialProps(
        args,
        blocks[index],
      );
    }
  }

  return { blocks };
};

Paragraphs.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.shape),
  layout: PropTypes.oneOf(['twoColumns', 'centered', 'left']),
};

export default Paragraphs;
