import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../../../01_atoms/Link';
import Button from '../../../../01_atoms/Button';
import TagText from '../../../../01_atoms/Tag/TagText';
import ResponsiveHeroImage, { propTypes as imagePropTypes } from '../index';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

import baseStyles from '../index.module.scss';
import styles from './index.module.scss';

const BBHeroEmergency = ({
  title,
  description,
  image,
  button = null,
  tag = null,
  tagLabel = 'Emergency',
  showDEC = false,
  behaviorSettings = null,
  uuid = null,
}) => (
  <div
    className={`bb bb-hero ${baseStyles['bb-hero']} bb-hero-emergency ${
      styles['bb-hero-emergency']
    } ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    title={image && image.medium && image.medium.alt ? image.medium.alt : null}
    id={uuid}
  >
    <ResponsiveHeroImage image={image} />

    {showDEC && (
      <>
        <div className="red-strip" />
        <img
          src="/static/icons/DEC.svg"
          className="watermark"
          alt="DEC logo"
          width={76}
          height={132}
        />
      </>
    )}
    <div className="gradient" />

    <div className="container-wrapper">
      <div className="container">
        <div className="row">
          <div className="overlay col-12 col-lg-10 col-xl-8">
            {/* We use empty title to overwrite title for the block. */}
            <TagText title="" label={tagLabel} backgroundColor="red" isUppercase />

            {/* We use empty title to overwrite title for the block. */}
            {tag && <TagText title="" label={tag} backgroundColor="black-emergency" isUppercase />}

            {/* We use empty title to overwrite title for the block. */}
            <h1 title="" className="header-two rockitt">
              {title}
            </h1>

            {/* We use empty title to overwrite title for the block. */}
            {description && (
              <p title="" className="d-none d-md-block">
                {description}
              </p>
            )}

            {button && button.label && button.nextLink && (
              <Link {...button.nextLink}>
                <Button
                  tag="a"
                  // We use empty title to overwrite title for the block.
                  title=""
                  type="emergency"
                  isMobileBlock
                  href={button.nextLink.url}
                  className="bb-hero-emergency--button bb-hero--button"
                >
                  {button.label}
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

BBHeroEmergency.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape(imagePropTypes).isRequired,
  button: PropTypes.shape({
    nextLink: PropTypes.shape(linkPropTypes),
    label: PropTypes.string,
  }),
  tag: PropTypes.string,
  showDEC: PropTypes.bool,
  tagLabel: PropTypes.string,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBHeroEmergency;
