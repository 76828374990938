import React from 'react';
import PropTypes from 'prop-types';

import { PTBreadcrumbs } from '../../../../01_atoms/Breadcrumbs/prop-types.js';
import Breadcrumbs from '../../../../01_atoms/Breadcrumbs';
import ResponsiveHeroImage, { propTypes as imagePropTypes } from '../index';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

import baseStyles from '../index.module.scss';
import styles from './index.module.scss';

const BBHeroImage = ({
  title,
  subtitle = null,
  image,
  breadcrumbs = null,
  showDEC = false,
  behaviorSettings = null,
  uuid = null,
}) => (
  <div
    className={`bb bb-hero bb-hero-image ${baseStyles['bb-hero']} ${
      styles['bb-hero-image']
    } ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    title={image && image.medium && image.medium.alt ? image.medium.alt : null}
    id={uuid}
  >
    <div className="image-container">
      <ResponsiveHeroImage image={image} />

      {showDEC && (
        <>
          <div className="red-strip" />
          <img
            src="/static/icons/DEC.svg"
            className="watermark"
            alt="DEC logo"
            width={95}
            height={165}
          />
        </>
      )}
      <div className="gradient gradient-mobile d-md-none" />
      <div className="gradient d-none d-md-block" />

      <div className="container-wrapper">
        <div className="container">
          <div className="row">
            <div className="overlay col-12 col-lg-10 col-xl-8">
              {breadcrumbs && (
                <div className="d-none d-md-block">
                  <Breadcrumbs {...breadcrumbs} />
                </div>
              )}

              <div className="d-none d-md-block">
                {/* We use empty title to overwrite title for the block. */}
                <h1 title="">{title}</h1>
                {/* We use empty title to overwrite title for the block. */}
                {subtitle && <h2 title="">{subtitle}</h2>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container d-md-none">
      {/* We use empty title to overwrite title for the block. */}
      <div className="header-one" title="">
        {title}
      </div>
      {/* We use empty title to overwrite title for the block. */}
      {subtitle && (
        <div className="header-two" title="">
          {subtitle}
        </div>
      )}
    </div>
  </div>
);

BBHeroImage.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  image: PropTypes.shape(imagePropTypes).isRequired,
  breadcrumbs: PTBreadcrumbs,
  showDEC: PropTypes.bool,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBHeroImage;
