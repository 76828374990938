import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import withCookieConsentVideo from './withCookieConsentVideo';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const ReactPlayer = dynamic(() => import('react-player'));

class BBVideo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPlaying: false,
      isLoading: false,
      isPreviewVisible: true,
    };

    this.onPreviewImageClick = this.onPreviewImageClick.bind(this);
  }

  componentDidMount() {
    const { autoPlay } = this.props;
    if (autoPlay) {
      this.onPreviewImageClick();
    }
  }

  /**
   * Handles click on video preview image.
   */
  onPreviewImageClick() {
    // Start loading animation & video loading.
    this.setState({
      isLoading: true,
      isPlaying: true,
    });

    // Will hide poster with animation (opacity:0).
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 1000);

    // Will completely hide poster with display:none.
    setTimeout(() => {
      this.setState({
        isPreviewVisible: false,
      });
    }, 1200);
  }

  render() {
    const { videoURL, previewImage, previewTitle, behaviorSettings, uuid } = this.props;
    const { isPlaying, isLoading, isPreviewVisible } = this.state;

    const blockClasses = ['video-player-block'];

    if (isLoading) {
      blockClasses.push('loading');
    }

    if (isPlaying) {
      blockClasses.push('playing');
    }

    const classes = [
      'bb',
      styles['bb-video'],
      generateClassNameByBehaviorSettings(behaviorSettings),
    ];

    return (
      <div className={`${classes.join(' ')}`} id={uuid}>
        <div className="container">
          <div className={blockClasses.join(' ')}>
            {isPlaying && (
              <ReactPlayer
                url={videoURL}
                className="video-player"
                width="100%"
                height="100%"
                controls
                playing={isPlaying}
              />
            )}

            {isPreviewVisible && !isPlaying && (
              <div
                className="poster"
                onClick={this.onPreviewImageClick}
                onKeyPress={this.onPreviewImageClick}
              >
                <div className="preview">
                  <img
                    height="383"
                    width="680"
                    className="preview-img"
                    src={
                      previewImage && previewImage.url
                        ? previewImage.url
                        : '/static/icons/logos/video-placeholder.svg'
                    }
                    alt={previewImage ? previewImage.alt : 'video-preview'}
                    loading="lazy"
                  />
                  {previewTitle && (
                    <div className="preview-header">
                      <img
                        src="/static/icons/logos/short-logo.svg"
                        alt="short logo"
                        width={50}
                        height={50}
                      />
                      <span>{previewTitle}</span>
                    </div>
                  )}
                  <img
                    className="play-button"
                    src="/static/icons/play.svg"
                    alt="play video icon"
                    width={100}
                    height={100}
                  />
                  {isLoading && (
                    <img
                      className="play-button--loading"
                      src="/static/icons/loading.svg"
                      alt="loading video icon"
                      width={100}
                      height={100}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

BBVideo.propTypes = {
  videoURL: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  previewImage: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  previewTitle: PropTypes.string,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

BBVideo.defaultProps = {
  autoPlay: false,
  previewTitle: '',
  behaviorSettings: null,
  uuid: null,
};

export default withCookieConsentVideo(BBVideo);
