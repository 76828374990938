import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';
import Link, { propTypes as linkPropTypes } from '../../01_atoms/Link';
import { LinkTitle } from '../Link';

class IconStat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hovering: false,
    };
  }

  render() {
    const { iconURL, title, nextLink, description, size, className } = this.props;
    const { hovering } = this.state;

    const IconStatCore = (
      <div className={`d-flex icon-stat ${styles['icon-stat']}`}>
        {iconURL && (
          <span className="image-container">
            <img
              className={`icon-stat-icon ${size}`}
              src={`${iconURL}`}
              alt={`${title} icon`}
              loading="lazy"
              width={64}
              height={64}
            />
          </span>
        )}

        <div>
          {nextLink ? (
            <LinkTitle label={title} hover={hovering} />
          ) : (
            <div className="header-one">{title}</div>
          )}

          <p className={nextLink ? 'd-none d-md-block' : ''}>{description}</p>
        </div>
      </div>
    );

    return nextLink ? (
      <Link {...nextLink}>
        <a
          onMouseEnter={() => this.setState({ hovering: true })}
          onMouseLeave={() => this.setState({ hovering: false })}
          className={`icon-stat-link-wrapper ${styles['icon-stat-link-wrapper']} ${className}`}
        >
          {IconStatCore}
        </a>
      </Link>
    ) : (
      IconStatCore
    );
  }
}

IconStat.propTypes = {
  iconURL: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  nextLink: PropTypes.shape(linkPropTypes),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  className: PropTypes.string,
};

IconStat.defaultProps = {
  nextLink: null,
  size: 'medium',
  className: '',
};

export default IconStat;
