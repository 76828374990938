import React from 'react';
import PropTypes from 'prop-types';
import HeroBackground from '../HeroBackground';
import { propTypes as defaultPicturePropTypes } from '../../../../01_atoms/DefaultPicture';
import TagText from '../../../../01_atoms/Tag/TagText';
import Button from '../../../../01_atoms/Button';
import Link, { propTypes as linkPropTypes } from '../../../../01_atoms/Link';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBHeroEmergencyOverlay = ({
  imagePicture,
  videoURL = null,
  tagLabel = 'Emergency',
  title,
  description,
  button,
  alignment = 'left',
  showDEC = false,
  behaviorSettings = null,
  uuid = null,
}) => (
  <HeroBackground
    uuid={uuid}
    className={`bb-hero-emergency-overlay ${
      styles['bb-hero-emergency-overlay']
    } ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    imagePicture={imagePicture}
    videoURL={videoURL}
  >
    {showDEC && (
      <div
        className={`bb-hero-emergency-overlay__red-strip bb-hero-emergency-overlay__red-strip--${alignment}`}
      >
        <div className="container d-lg-none">
          <div className="bb-hero-emergency-overlay__dec-logo-mobile">
            <img src="/static/icons/DEC-small.svg" width="138" height="40" alt="DEC logo" />
          </div>
        </div>
        <div className="bb-hero-emergency-overlay__dec-logo-desktop d-none d-lg-block">
          <img src="/static/icons/DEC.svg" width="80" height="139" alt="DEC logo" />
        </div>
      </div>
    )}

    <div className="container-wrapper">
      <div className="container">
        <div className="bb-hero-emergency-overlay__overlay-wrapper">
          <div
            className={`bb-hero-emergency-overlay__overlay bb-hero-emergency-overlay__overlay--${alignment}`}
          >
            <TagText label={tagLabel} backgroundColor="red" isUppercase />

            <h1>{title}</h1>
            <p>{description}</p>
            {button && button.nextLink && button.nextLink.href && (
              <Link {...button.nextLink}>
                <Button
                  type="emergency"
                  tag="a"
                  withArrow
                  isMobileBlock
                  data-category="Hero Emergency Overlay"
                  data-variant={alignment}
                >
                  {button.label}
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  </HeroBackground>
);

BBHeroEmergencyOverlay.propTypes = {
  imagePicture: PropTypes.shape(defaultPicturePropTypes).isRequired,
  videoURL: PropTypes.string,
  tagLabel: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.shape({
    nextLink: PropTypes.shape(linkPropTypes),
    label: PropTypes.string,
  }).isRequired,
  alignment: PropTypes.oneOf(['left', 'right']),
  showDEC: PropTypes.bool,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBHeroEmergencyOverlay;
