import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../01_atoms/Button';
import FormattedText from '../../01_atoms/FormattedText';

const CookieConsentDialog = ({
  className = '',
  title = '',
  description = '',
  onAccept,
  onDecline = null,
  buttonClassName = '',
  logo = null,
}) => (
  <div className={`cw-cookie-consent-dialog ${className}`}>
    <div>
      {logo && logo.url && <img src={logo.url} alt={logo.alt} width={76} height={32} />}
      <h4 className="title">{title}</h4>
      {description && <FormattedText className="description" text={description} />}
      <div className="buttons">
        {onDecline && (
          <div
            className="link ignore-button"
            onClick={onDecline}
            onKeyDown={(e) =>
              (e.key === 'Enter' || e.key === 'Space' || e.key === ' ') && onDecline()
            }
            tabIndex="0"
          >
            Ignore
          </div>
        )}
        <Button className={buttonClassName} type="secondary" onClick={onAccept}>
          Allow cookies
        </Button>
      </div>
    </div>
  </div>
);

CookieConsentDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func,
  logo: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
};

export default CookieConsentDialog;
