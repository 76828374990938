import React from 'react';
import PropTypes from 'prop-types';
import Popup from '../../../02_molecules/Popup';
import CookieConsentDialog from '../../../02_molecules/CookieConsentDialog';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';

/* global Cookiebot */
const withCookieConsentVideo = (VideoBlock) => {
  class VideoBlockWithCookieConsent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isCookiebotLoaded: false,
        isComponentVisible: false,
        isDialogVisible: false,
        isSubmitted: false,
      };

      this.onCookiebotLoad = this.onCookiebotLoad.bind(this);
      this.onAcceptCookie = this.onAcceptCookie.bind(this);
      this.onCancelDialog = this.onCancelDialog.bind(this);
    }

    componentDidMount() {
      const { cookieConsentType } = this.props;
      if (cookieConsentType) {
        window.addEventListener('CookiebotOnConsentReady', this.onCookiebotLoad);
      }

      // In case when Cookibot already loaded we need to switch component to
      // the right state. For example when we open popup with embedded video.
      if (typeof Cookiebot !== 'undefined') {
        this.onCookiebotLoad();
      }
    }

    componentWillUnmount() {
      const { cookieConsentType } = this.props;
      if (cookieConsentType) {
        window.removeEventListener('CookiebotOnConsentReady', this.onCookiebotLoad);
      }
    }

    onCookiebotLoad() {
      const { cookieConsentType } = this.props;

      if (Cookiebot && Cookiebot.consent && Cookiebot.consent[cookieConsentType]) {
        this.setState({
          isCookiebotLoaded: true,
          isComponentVisible: true,
          isDialogVisible: false,
        });
      } else {
        this.setState({
          isCookiebotLoaded: true,
          isComponentVisible: false,
          isDialogVisible: false,
        });
      }
    }

    onAcceptCookie(preferences, statistics, marketing) {
      const { isExample } = this.props;
      if (isExample) {
        this.setState({ isCookiebotLoaded: true, isComponentVisible: true, isSubmitted: true });
      } else {
        Cookiebot.submitCustomConsent(preferences, statistics, marketing);
        this.setState({ isSubmitted: true });
      }
    }

    onCancelDialog() {
      this.setState({ isDialogVisible: false });
    }

    render() {
      const {
        isExample,
        cookieConsentType,
        cookieDialogHeading,
        cookieDialogDescription,
        previewImage,
        previewTitle,
        behaviorSettings,
        uuid,
      } = this.props;
      const { isCookiebotLoaded, isComponentVisible, isDialogVisible, isSubmitted } = this.state;

      // We expect that cookieConsentType will be always defined. Otherwise
      // we think that component doesn't expect certain level of cookies, so
      // we can render component as is.
      if (!cookieConsentType && !isExample) {
        return <VideoBlock {...this.props} />;
      }

      let preferences;
      let statistics;
      let marketing;

      if (isCookiebotLoaded || isExample) {
        if (isComponentVisible) {
          return <VideoBlock {...this.props} autoPlay={isSubmitted} />;
        }

        if (!isExample) {
          preferences = cookieConsentType === 'preferences';
          statistics = cookieConsentType === 'statistics';
          marketing = cookieConsentType === 'marketing';

          if (Cookiebot && Cookiebot.consent) {
            preferences = preferences || Cookiebot.consent.preferences;
            statistics = statistics || Cookiebot.consent.statistics;
            marketing = marketing || Cookiebot.consent.marketing;
          }
        }
      }

      const title =
        cookieDialogHeading || `Please accept ${cookieConsentType} cookies to view this content.`;

      let preview = previewImage;

      if (!preview || !preview.url) {
        preview = { url: '/static/icons/logos/video-placeholder.svg' };
      }

      const classes = [
        'bb',
        styles['bb-video'],
        'bb-video-with-cookie',
        generateClassNameByBehaviorSettings(behaviorSettings),
      ];

      return (
        <div className={classes.join(' ')} id={uuid}>
          <div className="container">
            <div className="poster">
              <div
                className="preview"
                onClick={() => this.setState({ isDialogVisible: true })}
                onKeyPress={() => this.setState({ isDialogVisible: true })}
              >
                <img
                  height="383"
                  width="680"
                  className="preview-img"
                  src={preview.url}
                  alt={preview.alt || 'video-preview'}
                  loading="lazy"
                />
                {previewTitle && (
                  <div className="preview-header">
                    <img
                      src="/static/icons/logos/short-logo.svg"
                      alt="short logo"
                      width={50}
                      height={50}
                    />
                    <span>{previewTitle}</span>
                  </div>
                )}
                <img
                  className="play-button"
                  src="/static/icons/play.svg"
                  alt="play video icon"
                  width={100}
                  height={100}
                />
              </div>

              {isDialogVisible && (
                <>
                  <Popup
                    className="popup-cookie-consent-dialog d-md-none"
                    isVisible={isDialogVisible}
                    onPopupClose={() => this.onCancelDialog()}
                  >
                    <CookieConsentDialog
                      className="cookie-consent-context-dialog"
                      logo={{ url: '/static/icons/social/youtube.svg', alt: 'youtube logo' }}
                      title={title}
                      description={cookieDialogDescription}
                      onAccept={() => this.onAcceptCookie(preferences, statistics, marketing)}
                      onDecline={() => this.onCancelDialog()}
                      buttonClassName="allow-cookies-video-bb"
                    />
                  </Popup>
                  <CookieConsentDialog
                    className="cookie-consent-context-dialog d-none d-md-block"
                    logo={{ url: '/static/icons/social/youtube.svg', alt: 'youtube logo' }}
                    title={title}
                    description={cookieDialogDescription}
                    onAccept={() => this.onAcceptCookie(preferences, statistics, marketing)}
                    onDecline={this.onCancelDialog}
                    buttonClassName="allow-cookies-video-bb"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  VideoBlockWithCookieConsent.propTypes = {
    // isExample param can be used in case when we have to gain
    // the same behaviour of the component without Cookiebot.
    // Already used on the Body Blocks page in styleguide.
    isExample: PropTypes.bool,
    cookieConsentType: PropTypes.string,
    cookieDialogHeading: PropTypes.string,
    cookieDialogDescription: PropTypes.string,
    videoURL: PropTypes.string.isRequired,
    previewImage: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    previewTitle: PropTypes.string,
    behaviorSettings: behaviorSettingsProps,
    uuid: PropTypes.string,
  };

  VideoBlockWithCookieConsent.defaultProps = {
    isExample: false,
    cookieConsentType: '',
    cookieDialogHeading: '',
    cookieDialogDescription: '',
    previewTitle: '',
    behaviorSettings: null,
    uuid: null,
  };

  return VideoBlockWithCookieConsent;
};

export default withCookieConsentVideo;
