import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../../../../hooks/useScreenSize';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';
import { formattedDate } from '../../../../utils/date-formatter.js';
import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';
import Slider from '../../../01_atoms/Slider';
import FormattedText from '../../../01_atoms/FormattedText';

import styles from './index.module.scss';

const BBImpactTimeline = ({ title, items, behaviorSettings, uuid }) => {
  const [state, setState] = useState({
    sliderSettings: {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      navClass: 'bb-impact-timeline--navigation',
    },
    isInitialized: false,
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    popupItemIndex: null,
  });
  const { width } = useScreenSize();

  const onPopupShow = useCallback((popupItemIndex) => {
    setState((prev) => ({ ...prev, popupItemIndex }));
  }, []);

  const onPopupHide = useCallback(() => {
    setState((prev) => ({ ...prev, popupItemIndex: null }));
  }, []);

  const onEscKeyPress = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        onPopupHide();
      }
    },
    [onPopupHide],
  );

  if (width >= 992 && !state.isDesktop) {
    setState((prev) => ({
      ...prev,
      isInitialized: true,
      isDesktop: true,
      isTablet: false,
      isMobile: false,
      sliderSettings: {
        ...prev.sliderSettings,
        slidesToShow: 3,
        initialSlide: items.length - 3,
      },
    }));
  }

  if (width >= 768 && width < 992 && !state.isTablet) {
    setState((prev) => ({
      ...prev,
      isInitialized: true,
      isDesktop: false,
      isTablet: true,
      isMobile: false,
      sliderSettings: {
        ...prev.sliderSettings,
        slidesToShow: 2,
        initialSlide: items.length - 2,
      },
    }));
  }

  if (width < 768 && !state.isMobile) {
    setState((prev) => ({
      ...prev,
      isInitialized: true,
      isDesktop: false,
      isTablet: false,
      isMobile: true,
      sliderSettings: {
        ...prev.sliderSettings,
        slidesToShow: 1,
        initialSlide: items.length - 1,
      },
    }));
  }

  useEffect(() => {
    window.addEventListener('keydown', onEscKeyPress, false);

    return () => {
      window.removeEventListener('keydown', onEscKeyPress, false);
    };
  }, [onEscKeyPress]);

  if (!state.isInitialized) {
    return null;
  }

  const classes = [
    'bb',
    styles['bb-impact-timeline'],
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];

  return (
    <div className={classes.join(' ')} id={uuid}>
      <div className="container">
        {title && <h2>{title}</h2>}

        <Slider {...state.sliderSettings}>
          {items.map((item, index) => (
            <div className="item" key={item.title}>
              {item.previewImage && (
                <div
                  className="image bb-impact-timeline--popup"
                  onClick={() => (!state.isMobile ? onPopupShow(index) : null)}
                  onKeyDown={() => (!state.isMobile ? onPopupShow(index) : null)}
                >
                  <img
                    src={item.previewImage.url}
                    alt={item.previewImage.alt}
                    width={90}
                    height={90}
                  />
                  <div className="popover d-none d-md-block" />
                </div>
              )}

              <div className={`status ${item.isActiveAppeal ? 'active' : ''}`}>
                {item.isActiveAppeal ? 'Active appeal' : 'Completed appeal'}
              </div>

              {item.title && <div className="title">{item.title}</div>}

              {item.previewDescription && (
                <div className="short-description">{item.previewDescription}</div>
              )}

              {item.nextLink && (
                <Link {...item.nextLink}>
                  <Button
                    href={item.nextLink.url}
                    tag="a"
                    className="cta bb-impact-timeline--button"
                    size="small"
                  >
                    Support this appeal
                  </Button>
                </Link>
              )}

              <div className="spot">
                <span className="border" />
                <span className="circle">
                  {item.isActiveAppeal ? (
                    <img src="/static/icons/pink-exclamation.svg" alt="Active appeal" />
                  ) : (
                    <img src="/static/icons/teal-tick.svg" alt="Successful appeal" />
                  )}
                </span>
                <span className="date">{formattedDate(item.timestamp)}</span>
              </div>
            </div>
          ))}
        </Slider>

        <div className={styles['bb-impact-timeline__timeline']}>
          <div className={styles['bb-impact-timeline__start']} />
          <div className={styles['bb-impact-timeline__end']} />
        </div>

        {state.popupItemIndex !== null && (
          <div className="popup">
            <div className="body">
              <span className="close-btn" onClick={onPopupHide} onKeyDown={onPopupHide} />

              <img
                className="left"
                src={items[state.popupItemIndex].image.url}
                alt={items[state.popupItemIndex].image.alt}
              />

              <div className="content">
                <p className="title">
                  <strong>{items[state.popupItemIndex].title}</strong>
                </p>

                <FormattedText
                  className="description"
                  text={items[state.popupItemIndex].description}
                />

                {items[state.popupItemIndex].nextLink && (
                  <Link {...items[state.popupItemIndex].nextLink}>
                    <Button
                      href={items[state.popupItemIndex].nextLink.url}
                      tag="a"
                      className="bb-impact-timeline-popup--button"
                    >
                      Support this appeal
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

BBImpactTimeline.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      previewDescription: PropTypes.string,
      previewImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
      description: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
      nextLink: PropTypes.shape(linkPropTypes),
      isActiveAppeal: PropTypes.bool,
      timestamp: PropTypes.number,
    }),
  ).isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

BBImpactTimeline.defaultProps = {
  title: '',
  behaviorSettings: null,
  uuid: null,
};

export default BBImpactTimeline;
