import React from 'react';

import { PTHubResultCard } from './prop-types';
import NextArrow from '../../../../public/static/icons/next-arrow.svg';
import ExternalLink from '../../../../public/static/icons/external-link.svg';
import { getResultCardIcon } from '../../../../utils/transforms.elastic_search.js';
import Link from '../../../01_atoms/Link';

import styles from './index.module.scss';
import { formattedDate } from '../../../../utils/date-formatter';
import FormattedText from '../../../01_atoms/FormattedText';

const HubResultCard = ({
  type,
  resourceType,
  title,
  timestamp,
  description,
  image,
  link,
  featured = false,
  variant = 'wide',
}) => {
  const icon = getResultCardIcon(type, resourceType);

  return (
    <div className={`hub-result-card ${styles['hub-result-card']} hub-result-card-${variant}`}>
      <Link {...link}>
        <a
          href={link.url}
          target={link.target}
          className={`hub-result-card-container ${featured ? 'featured' : ''}`}
          tabIndex="0"
        >
          <div className="result-image-wrapper">
            <img
              src={image.url}
              className="result-image"
              alt={image.alt}
              width={270}
              height={162}
            />
          </div>
          <div className="result-type">
            {icon && <img src={icon} className="result-icon" alt="" width={25} height={25} />}
            {resourceType}
          </div>
          <h3>
            {title}
            {type && type === 'publication_external' && (
              <ExternalLink alt="Opens a new window" aria-label="Opens a new window" />
            )}
          </h3>
          <div className="result-byline">
            {type && type === 'publication_external' && link && link.hostname && (
              <span className="result-external">
                External resource from <strong>{link.hostname}</strong> |&nbsp;
              </span>
            )}
            <span className="result-date">Last updated: {formattedDate(timestamp)}</span>
          </div>
          <FormattedText className="result-description" text={description} />
          {type && type === 'publication_landing' && (
            <div className="result-more">
              Learn more
              <span className="arrow" aria-hidden="true">
                <NextArrow />
              </span>
            </div>
          )}
        </a>
      </Link>
    </div>
  );
};

HubResultCard.propTypes = PTHubResultCard;

export default HubResultCard;
